import * as React from "react"
import { Link } from "gatsby"
import Button from "../components/Button"
import Layout from "../components/Layout"
import type { HeadFC, PageProps } from "gatsby"

const offers = [
  {
    name: "Wizyta w gabinecie",
    description: ["wtorek, czwartek"],
    href: "/wizyta-w-gabinecie",
  },
  {
    name: "Wizyta w domu u pacjenta",
    description: ["poniedziałek, środa, piątek"],
    href: "/wizyta-domowa",
  }
]

const IndexPage: React.FC<PageProps> = () => {
  return (
    <Layout pageTitle="Umów wizytę">
      <div className="p-6 border-b">
        <h1 className="text-3xl lg:text-4xl font-medium leading-normal text-gray-900">Contact Form Example</h1>
        <p className="lg:text-xl text-gray-700">Przykładowy formularz - proszę nie podawać tu swoich prawdziwych danych</p>
        <p className="lg:text-xl text-gray-700">This is just an example app - do not enter your real data here!</p>
      </div>
      <section className="text-gray-600 body-font">
        <div className="grid grid-cols-2 py-6 gap-8">
          {offers.map(offer => (
            <div key={offer.name} className="col-span-2 lg:col-span-1 p-6 h-full flex flex-col border border-gray-200 items-center bg-white sm:rounded-lg shadow">
              <h2 className="text-2xl font-medium mb-3">{offer.name}</h2>
              {offer.description.map(item => (
                <p key={item} className="mb-3">{item}</p>
              ))}
              <Button as={Link} to={offer.href} className="w-fit mt-auto m-4">Wybierz</Button>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
